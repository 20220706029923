@import 'styles/variables';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/vendor/rfs';

$tooltip-bg: $color-blue;
$tooltip-arrow-color: $color-blue;
$tooltip-opacity: 1;
$tooltip-color: $color-white;
$tooltip-max-width: 280px;


@import 'bootstrap/scss/root';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/dropdown';

a {
  text-decoration: none;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: $color-blue;
}