$font-family-text: 'Lato', 'sans-serif', 'Roboto', 'RobotoFallback', 'Helvetica',  'Arial', sans-serif;
$font-family-title: 'Anton', 'Impact', 'sans-serif';
$font-family-monospace: 'Courier New', Courier, monospace;

$font-xxs: 11px;
$font-xs: 13px;
$font-sm: 14px;
$font-md: 15px;
$font-lg: 16px;
$font-xl: 20px;
$font-xxl: 23px;

$font-title-sm: 18px;
$font-title-lg: 22px;

$font-light: 300;
$font-normal: 400;
$font-bold: 700;
$font-extra-bold: 900;