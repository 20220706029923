// Overrides default bootstrap modal styles

.modal {
  bottom: 0;
  height: auto !important;
  overflow: hidden !important;
  display: block !important;

  .modal-dialog {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;

    .modal-content {
      position: static;
      width: 95%;
      height: 95%;
      max-width: 800px;
      max-height: 1000px;
      margin: 0;
      border-radius: 0;
    }
  }

  &.small {
    .modal-content {
      position: static;
      width: 90%;
      height: 90%;
      max-width: 500px;
      max-height: 700px;
    }
  }
  &.embeeded {
    .modal-content {
      position: static;
      width: 90%;
      height: 90%;
      max-width: 700px;
      max-height: 850px;
    }
  }
  &.wide {
    .modal-content {
      max-width: none;
      max-height: none;
    }
  }
  &.fullscreen {
    .modal-content {
      max-width: none;
      max-height: none;
      width: 100%;
      height: 100%;
    }
  }
}
.modal:not(.show),
.modal:not(.show) *,
.modal-backdrop:not(.show),
.modal-backdrop:not(.show) * {
  pointer-events: none !important;
}