$color-blue: #21243d;
$color-pink: #ff7c7c;
$color-yellow: #ffd082;
$color-cyan: #88e1f2;

$color-theme1: #ffffff;
$color-theme1-contrast: #9575cd;
$color-theme2: #3B3C66;
$color-theme2-contrast: #ffffff;

$color-white: white;
$color-black: #121212;
$color-text: $color-blue;
$color-link: #007bff;
$color-error: #df0000;
$color-form-bg: #ff5722;
$color-silver-light: #ededed;
$color-silver-dark: #999;
$color-whatsapp: #25d366;
$color-gray-80: #808080;
$color-gray-60: #C0C0C0;
$color-gray-40: #EDEDED;
$color-gray-20: #F8F8F8;
$color-helper: $color-gray-80;

$color-neutral: $color-gray-80;
$color-neutral-bg: $color-gray-20;
$color-neutral-border: $color-gray-60;

$color-success: #155724;
$color-success-bg: #d4edda;
$color-success-border: #c3e6cb;
$color-success-accented: #9be963;

$color-warning: #856404;
$color-warning-bg: #fff3cd;
$color-warning-border: #ffeeba;
$color-warning-accented: $color-yellow;

$color-info: #0c5460;
$color-info-bg: #d1ecf1;
$color-info-border: #bee5eb;
$color-info-accented: #2196F3;

$color-danger: #721c24;
$color-danger-bg: #f8d7da;
$color-danger-border: #f5c6cb;
$color-danger-accented: #b3001e;

$shadow-color: rgba($color-black, 0.07);

$box-shadow: 0px 1px 2px $shadow-color, 0px 2px 4px $shadow-color, 0px 4px 8px $shadow-color, 0px 8px 16px $shadow-color, 0px 16px 32px $shadow-color, 0px 32px 64px $shadow-color;
$box-shadow-sm: 0px 0.5px 1px $shadow-color, 0px 1px 2px $shadow-color, 0px 2px 4px $shadow-color, 0px 4px 8px $shadow-color, 0px 8px 16px $shadow-color, 0px 16px 32px $shadow-color;
