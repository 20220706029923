@import 'styles/variables';

.ngx-toastr {
  font-family: $font-family-text !important;
  font-size: 15px;
  box-shadow: 0 0 42px #777777 !important;
}
.toast-top-center {
  top: 20px !important;
}
.toast-success {
  background-color: var(--cyan) !important;
}

.toast-error {
  background-color: #ff7c7c !important;
}
.toast-title {
  font-weight: 900 !important;
}
.toast-container .toast-close-button {
  top: -0.6em !important;
}
