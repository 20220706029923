@import './variables';
@import './mixins';

// Custom UI styling
// Rules:
// - Use `ui-` prefix for app-global styles
// - Use BEM notation for block, elements and modifiers
// - Use SCSS nesting for shorthand elements' and modifiers' selectors under a given block, e.g.: &__element or &--modifier
// - For any other namespace (3rd party library) customizations, create a separate partial file and @import it here before this comment

.ui-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  color: $color-yellow;
  transition: color 0.5s ease-out;

  &--with-overlay {
    background: rgba($color-black, 0.3);
    z-index: 99999;
  }

  &--local {
    position: absolute;
    z-index: 1;
  }

  &--inline-block {
    padding: 0.5em;
    position: static;
  }

  &--non-blocking {
    pointer-events: none;
  }

  pxw-icon svg {
    width: $ui-loader-size !important;
    height: $ui-loader-size !important;
  }

  &--size-sm pxw-icon svg {
    width: $ui-loader-size-sm !important;
    height: $ui-loader-size-sm !important;
  }

  &__preboot-spinner {
    overflow: visible;
    width: $ui-loader-size !important;
    height: $ui-loader-size !important;
    animation: icon-spin 2s infinite linear;
    display: inline-block;
    color: $color-yellow;

    @keyframes icon-spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
      }
    }
  }
}

.ui-animation {
  &--pulse-opacity {
    animation: ui-animation--pulse-opacity 1s ease-in-out infinite alternate;

    @keyframes ui-animation--pulse-opacity {
      0% {
        opacity: 0.3;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.ui-title {
  font-family: $font-family-title;
  color: var(--theme2-contrast);
  font-size: $size-xxl;
  padding: 0 $app-margin-h 0;
  display: flex;
  align-items: center;
  margin: 0;
  line-height: 1.2;

  @media (max-width: 400px) {
    font-size: $size-xl;
  }

  pxw-icon {
    cursor: pointer;

    &[icon='back'] {
      margin-right: 0.4em;
    }
  }

  .ui-item-link {
    line-height: 1;
  }
}

.ui-subtitle {
  font-family: $font-family-text;
  color: $color-blue;
  font-size: $size-lg;
  margin: 0 0 1.2em;

  @media (max-width: $max-mobile) {
    font-size: $size-md;
  }
}

.ui-item-link {
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.ui-input {
  margin-bottom: $form-space-v;
  display: block;
  font-family: $font-family-text;

  &--disabled {
    background-color: rgba($color-gray-20, 0.4);
    border-color: $color-gray-20;
    cursor: not-allowed;

    .ui-input__input,
    .addon-prepend,
    .addon-append {
      color: rgba($color-text, 0.4) !important;
    }
    & .ui-input__editor {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__container--with-addons {
    display: grid !important;
    grid-template-columns: min-content 1fr min-content;
  }

  .ui-input__input {
    display: block;
    font-size: $font-sm;
    line-height: 1.1;
    background: transparent;
    color: $color-text;
    appearance: none;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0.8em;
    border: none;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: rgba($color-black, 0.3);
    }
  }
  &__error-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__error {
    display: block;
    margin: 0;
    color: $color-error;
    font-size: 12px;
    padding: 0.2em 0 0 0.1em;
  }
  &__editor {
    display: flex;
    align-items: center;

    .ui-input__input {
      flex: 100% 1 1;
    }
    .ui-input__error-icon {
      flex: min-content 0 0;
    }
  }
  &__error-icon {
    color: $color-error !important;
    padding-right: 0.5em;
  }

  &--with-helper {
    .dx-invalid {
      margin-bottom: 1.5em;
    }
  }

  &--validation-only .dx-invalid {
    margin-bottom: 0;
  }

  &--inline {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ui-label {
      margin: 0 0.5em 0 0;
    }
    .ui-input__container {
      width: auto !important;
    }
  }

  & > pxw-text-field,
  & > pxw-checkbox-group,
  & > pxw-date-range,
  & > pxw-image {
    margin-bottom: 0;
  }

  &:not(.ui-input-checkbox) > .ui-input__container,
  &:not(.ui-input-checkbox) > .ui-input__wrapper .ui-input__container {
    width: 100%;
    display: flex;
    align-items: center;
    background: $color-gray-20;
    border: 1px solid darken($color-gray-20, 3);
    border-radius: $border-radius-sm;
  }

  dx-text-box,
  dx-text-area,
  dx-number-box,
  dx-date-box,
  dx-select-box {
    width: 100%;
  }

  .dx-texteditor,
  .ui-input__editor {
    border-radius: 0;
  }
  .ui-input__editor {
    width: 100%;
  }
  .dx-texteditor-container {
    width: 100%;
  }

  .dx-editor-outlined {
    box-shadow: none !important;
  }

  .ui-input__addon-wrapper {
    display: flex;
    height: 100%;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .addon-prepend + .dx-texteditor,
  .dx-texteditor + .addon-append,
  .ui-input__addon-wrapper:not(:empty) + .ui-input__editor,
  .ui-input__editor + .ui-input__addon-wrapper:not(:empty) {
    border-left: 1px solid $color-gray-40;
  }
  .addon-prepend,
  .addon-append,
  .dx-texteditor-input,
  .dx-placeholder::before {
    font-size: $font-sm;
    line-height: 1.1;
  }
  .dx-texteditor-input,
  .dx-placeholder::before {
    padding: 0.8em !important;
  }
  .addon-prepend,
  .addon-append {
    padding: 0 0.8em !important;
    color: $color-gray-80;
    margin: 0;
  }
  &.ui-input-textarea .dx-texteditor-input,
  &.ui-input-textarea .dx-placeholder::before {
    line-height: 1.5;
  }
}

.ui-form-fieldset {
  padding: 1.5em $app-margin-h 0.5em;
  border: 1px dotted silver;
  border-radius: $border-radius-sm;
  margin-top: 1em;
}

.ui-label {
  font-family: $font-family-text;
  color: $color-text;
  font-weight: $font-bold;
  line-height: 1;
  padding: 0;
  margin: 0 0 0.5em;
  font-size: $size-sm;
  display: block;

  &--xs {
    font-size: $size-xs;
    color: rgba($color-text, 0.8);
  }

  &--md {
    font-size: $size-md;
    color: $color-pink;
    margin-bottom: 1em;
  }

  &--required::after {
    content: ' *';
    color: $color-error;
    padding: 0 0.2em;
  }
}

.ui-helper {
  color: $color-helper;
  font-size: $font-xs;
  margin: 0.5em 0 1em;

  &__help-icon {
    color: $color-link;
  }
}

.ui-help-link {
  color: $color-link;
  cursor: pointer;

  &:hover {
    color: lighten($color: $color-link, $amount: 15);
    text-decoration: none;
  }
}

.ui-tooltip {
  font-style: italic;
  color: $color-gray-80;
  display: inline-block;
  border-bottom: 1px dashed $color-gray-60;
  cursor: pointer;

  &--disabled {
    border-bottom: none;
    cursor: not-allowed;
  }

  &:not(.ui-tooltip--disabled):hover {
    color: $color-gray-60;
  }

  &__content {
    .dx-popup-content {
      font-size: $font-sm !important;
      max-width: 250px;
      white-space: normal !important;
    }
    .dx-overlay-content {
      background: $color-blue !important;
    }
    .ui-help-link {
      color: $color-white;

      &:hover {
        color: rgba($color-white, 0.7);
      }
    }
  }
}

.ui-preboot {
  button *,
  a *,
  pxw-icon * {
    pointer-events: none;
  }
}

// Visibility in forms, e.g. product or category form
.ui-visibility {
  margin: 0.3em 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__separator {
    margin: 0 1em;
    height: 1.3em;
    border-left: 1px dotted $color-silver-dark;
  }
}

.ui-separator {
  border: none;
  border-top: 1px solid $color-gray-40;
  margin: 1.8em 0;
  opacity: 1;

  &--sm {
    margin: 1em 0;
  }
}

.ui-line-through {
  position: relative;
  margin: 1.8em 0;
  text-align: center;

  &--sm {
    margin: 1em 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0.85em;
    left: 0;
    right: 0;
    border-top: 1px solid $color-gray-40;
  }
  &__content {
    display: inline-block;
    background: $color-white;
    padding: 0 1em;
    font-size: 15px;
    color: $color-gray-80;
    font-style: italic;
    position: relative;
  }
}

.ui-clickable {
  cursor: pointer;
}
.ui-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ui-flex {
  display: flex;

  &--center-in-parent {
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.ui-table-title {
  font-weight: $font-bold;
  font-size: $font-xl;
  margin-bottom: 1em;
}
.ui-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $color-blue;

  &__heading-row {
    background: $color-blue;
    color: $color-white;
  }
  &__heading-col {
    font-size: $font-md;
  }
  &__heading-col {
    padding: 8px 12px;
  }
  &__body-col {
    padding: 12px 12px;
  }
  &__body-col {
    font-size: $font-sm;
  }
  &__body-row:nth-child(even) {
    background: $color-gray-20;
  }
  &__body-col & &__body-col {
    border-left: 1px solid $color-gray-40;
  }
}

.ui-popup {
  pxw-card {
    padding: 0;
  }
  .content.scroll-enabled {
    overflow-y: hidden !important;
    grid-template-rows: 100% !important;
  }
  .content > .body {
    padding: 0;
  }
}

.ui-popover-menu {
  padding: 0.5em !important;
}

.ui-hidden {
  &--mobile {
    @media (max-width: $max-mobile) {
      display: none;
    }
  }
  &--non-mobile {
    @media (min-width: $min-non-mobile) {
      display: none;
    }
  }
}

.ui-dropdown__backdrop {
  position: absolute;
  z-index: 10;
  left: -5000px;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  background: rgba($color-black, 0.2);
}

@media screen {
  .ui-printable {
    display: none !important;
  }
}
@media print {
  html,
  body {
    padding: 0 !important;
    margin: 0 !important;
    margin: 0;
    padding: 0;
  }
  body.ui-printable--active > .ui-printable {
    display: block !important;
    padding: 0.2em;
  }
  body > *:not(.ui-printable) {
    display: none !important;
  }
}
