#nubo-trigger.nubo-trigger .nubo-counter {
  position: absolute;
  right: -2px;
  height: 10px;
  width: 10px;
  font-size: 11px;
  padding: 0;
  line-height: 10px;
  font-weight: 600;
  top: 5px;
}
