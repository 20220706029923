.shepherd-element {
  text-align: center;
  border-radius: 0 !important;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5) !important;

  @media screen and (max-width: 420px) {
    max-width: 95% !important;
  }

  .shepherd-content {
    border-radius: 0;
  }

  .shepherd-header {
    background: $color-pink !important;
    padding: 0.7em 1.2em !important;
    border-radius: 0;
    margin-top: -1px;

    .shepherd-cancel-icon {
      font-size: 1.6em;
      margin-right: -0.2em;
      line-height: 1;
    }
    .shepherd-title,
    .shepherd-cancel-icon {
      color: white !important;
    }
  }
  .shepherd-text {
    padding: 1.6em 1.2em;
    font-size: 15px !important;
    line-height: 1.6 !important;
  }
  .shepherd-footer {
    justify-content: center;
  }

  .shepherd-button,
  .shepherd-button:hover {
    background: $color-blue !important;
    color: white !important;
  }

  .shepherd-button {
    font-size: 0.9em;
    width: 12em;
    padding: 0.6em 0.1em !important;

    &:hover {
      background: inherit;
    }

    &.shepherd-button-secondary {
      background: #f1f2f3 !important;
      color: rgba(0, 0, 0, 0.75) !important;
    }
  }

  .guided-tour-toggle-wrapper {
    padding: 0em 1.2em 0.2em;
    font-size: 14px;

    input {
      margin-right: 0.3em;
    }
  }
}