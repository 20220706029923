@import 'styles/variables';

.dx-list .dx-empty-message {
  font-size: $font-sm !important;
  color: rgba($color-blue, 0.3) !important;
  font-style: italic !important;
  padding: 1.2em 0.8em !important;
}

.dx-invalid.dx-texteditor.dx-show-invalid-badge .dx-texteditor-input-container:after {
  transform: scale(0.7);
}

.dx-placeholder {
  color: $color-blue !important;
  font-size: $font-sm !important;
  opacity: 0.3 !important;
}

.dx-invalid-message > .dx-overlay-content {
  padding: 0 !important;
  color: $color-error !important;
  background-color: transparent;
}

dx-button-group {
  .dx-button-mode-outlined {
    border-color: $color-blue;
    color: rgba($color-blue, 0.8);
  }
  .dx-buttongroup-item.dx-button-mode-outlined.dx-state-hover {
    background: rgba($color-blue, 0.1) !important;
  }
  .dx-buttongroup-item.dx-button-mode-outlined.dx-item-selected {
    background: rgba($color-blue, 0.9) !important;
    color: white !important;
  }
  @media (max-width: 400px) {
    .dx-button-has-text .dx-button-content {
      padding: 0.4em 0.8em;
      font-size: 0.9em;
    }
  }
}

// Dev-extreme validation errors by default displays with z-index bigger than modals
.dx-overlay-wrapper.dx-invalid-message {
  z-index: 1 !important;
}

.dx-selectbox-popup-wrapper {
  z-index: 6000 !important;
}

.dx-checkbox-icon {
  background: white;
}

// Actionsheets
.dx-actionsheet-popup-wrapper {
  .dx-overlay-content {
    border-radius: $border-radius-sm;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .dx-popup-content,
  .dx-actionsheet-item-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .dx-button {
    margin: 0;
    border: 1px solid var(--info-text);
    color: var(--info-text);
    font-family: $font-family-text;
    font-weight: bold;
    .dx-button-text {
      text-transform: initial;
      font-weight: bold;
      padding: 0;
    }
  }
}

.dx-actionsheet-popover-wrapper {
  .dx-overlay-content {
    margin-top: -90px;
    box-shadow: 0 7px 30px -10px rgb(150 170 180 / 50%);
  }

  .dx-popup-title {
    display: none;
  }
  .dx-popup-content {
    padding: 0;
  }
  .dx-button {
    border: 0;
    display: block;
  }
  .dx-actionsheet-item {
    padding: 0.8em 1.2em;
  }
  .dx-actionsheet-item-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
  .dx-button-text {
    font-weight: bold;
    text-transform: capitalize;
    font-family: $font-family-text;
    font-size: 16px;
    letter-spacing: 0;
  }
}

.dx-datebox-wrapper-calendar .dx-calendar-cell,
dx-date-box .dx-texteditor-input {
  text-transform: capitalize;
}

// - Tag Box
dx-tag-box.dx-state-focused {
  box-shadow: none;
}

// - Data Grid

dx-data-grid {

  .dx-row {
    border-top: none;
    border-bottom: none;

    &:not(.dx-row-alt) {
      background: $color-gray-20;

      &.dx-selection {
        background: #e7fbff;
      }
    }
    &.dx-row-alt {
      background: $color-gray-40;

      & > td {
        background: none;
      }

      &.dx-selection {
        background: #d5f8ff;
      }
    }
  }
  .dx-datagrid-headers [role=columnheader] {
    border-left: 1px solid $color-blue !important;
    border-right: 1px solid $color-blue !important;
  }
  .dx-datagrid-rowsview .dx-freespace-row td,
  .dx-datagrid-rowsview [role=gridcell] {
    border-left-width: 1px !important;
    border-right-width: 1px !important;
  }
  .dx-datagrid-headers {
    border: none !important;

    .dx-sort-indicator,
    .dx-column-indicators,
    .dx-sort,
    .dx-datagrid-text-content {
      color: $color-white !important;
    }
    .dx-header-row td {
      font-size: $font-md !important;
      padding: 1em !important;
    }
  }
  .dx-row.dx-header-row {
    background: $color-blue;
  }
  .dx-datagrid-revert-tooltip .dx-revert-button {
    background: $color-blue!important;
  }
  .dx-row {
    background: $color-white;
  }
  .dx-datagrid-rowsview .dx-row.dx-group-row {
    background: $color-gray-20;
    color: $color-black;
    text-transform: uppercase;
    font-weight: normal;
    text-indent: -1em;

    .dx-command-expand {
      text-indent: -0.4em;
    }
  }
  .cell-multiline {
    white-space: pre-wrap;
  }
}