/**
  - NOTE: include in this file ONLY styles that are application-aware
  - For global/default rules/behaviors/styling/resets, use `_defaults.scss`
*/

:root {
  --theme1: #{$color-theme1};
  --theme1-contrast: #{$color-theme1-contrast};
  --theme2: #{$color-theme2};
  --theme2-contrast: #{$color-theme2-contrast};
  --yellow: #{$color-yellow};
  --cyan: #{$color-cyan};

  --danger: #{$color-pink};
  --danger-bg: #{$color-pink};
  --danger-text: #{$color-blue};

  --success: #{$color-cyan};
  --success-bg: #{$color-cyan};
  --success-text: #{$color-blue};

  --info: #{$color-cyan};
  --info-bg: #{$color-cyan};
  --info-text: #{$color-blue};

  --warning: #{$color-yellow};
  --warning-bg: #{$color-yellow};
  --warning-text: #21243d;

  --white: #{$color-white};

  --link: #{$color-link};
}

html.ready {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* Fallback for older browsers */
  width: 100%;
  height: 100%;
  /* ---------------------------- */
  width: 100dvw;
  height: 100dvh;

  body {
    /* Fallback for older browsers */
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    /* ---------------------------- */
    height: 100dvh;
    max-height: 100dvh;
    min-height: 100dvh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    overflow: hidden;
    font-family: $font-family-text;
    color: $color-text;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .app-root,
  app-root {
    display: block;
    /* Fallback for older browsers */
    max-height: 100%;
    height: 100%;
    width: 100%;
    /* ---------------------------- */
    max-height: 100dvh;
    height: 100dvh;
    width: 100dvw;
    margin: 0 auto;
  }
}

pxw-text-field,
pxw-checkbox-group,
pxw-date-range,
pxw-image {
  margin-bottom: $form-space-v;
}
