$header-height: 55px;
$subtitle-bg-height: 110px;

// X-Small	None	<576px
// Small	sm	≥576px
// Medium	md	≥768px
// Large	lg	≥992px
// Extra large	xl	≥1200px
// Extra extra large	xxl	≥1400px
$width-sm: 576px; 
$width-md: 768px; 
$width-lg: 992px; 
$width-xl: 1200px; 
$max-width: $width-xl;

$max-mobile: $width-sm - 1px;
$min-non-mobile: $width-sm;
$min-tablet: $width-md;
$max-tablet: $width-lg - 1px;
$min-desktop: $width-lg;

// TODO: review sizes and rename accordingly: border-radius and border-radius-sm
$border-radius: 8px;
$border-radius-sm: 6px;
$border-radius-md: 10px;
$border-radius-lg: 15px;

$size-xxs: 11px;
$size-xs: 12px;
$size-sm: 14px;
$size-md: 16px;
$size-lg: 18px;
$size-xl: 20px;
$size-xxl: 22px;
$size-xxxl: 26px;

$button-height-sm: 31px;
$button-height-md: 45px;
$button-height-lg: 54px;

$form-space-v: 1.5em;
$app-margin-h: 1rem;

$navbar-height: 60px;
$closed-banner-height: 75px;

$admin-actions-width: 45px;

$icon-xs: 20px;
$icon-sm: 25px;
$icon-md: 32px;
$icon-lg: 40px;

$ui-loader-size-sm: 40px;
$ui-loader-size: 60px;
